import { Input } from 'antd'
import Styles from './OTP.module.scss'
import { FC, useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import classNames from 'classnames'

const MinResendInterval = 12
export const OTP: FC<{
  onComplete: (otp: string) => any
  lastOtpRequestedAt: Dayjs
  onRequestOtp: () => any
}> = ({
  onComplete, lastOtpRequestedAt, onRequestOtp
}) => {
    const [shouldShowResend, setShouldShowResend] = useState(false)
    // const [secondRemaining, setSecondRemaining] = useState(MinResendInterval)

    const requestOTP = async () => {
      if (shouldShowResend) {
        onRequestOtp()
        setShouldShowResend(false)
      }
    }

    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     const diff = lastOtpRequestedAt.diff(dayjs(), 'second')
    //     setShouldShowResend(diff < -MinResendInterval)
    //     setSecondRemaining(Math.max(0, MinResendInterval + diff))
    //   }, 1000)
    //   return () => clearInterval(interval)
    // }, [lastOtpRequestedAt.unix()])

    return <div className={Styles.otp}>
      <div className={Styles.description}>
        {'Please enter the OTP generated from the Authenticator app'}
      </div>
      <Input.OTP
        length={6}
        onChange={onComplete}
      />
      {/* <div
        className={classNames(Styles.resend, shouldShowResend && Styles.active)}
        onClick={requestOTP}>
        {shouldShowResend ? 'Resend OTP' : `Resend OTP in ${secondRemaining} seconds`}
      </div> */}
    </div>
  }
