import { useCallback, useEffect, useState } from 'react'
import { ChartPt, Station } from 'src/models'
import { last, mapValues } from 'lodash'
import { getStation } from 'src/api'
import { useParams } from 'react-router-dom'
import { useTimer } from 'src/hooks/useTimer'

export const useViewModel = () => {
  const { stnId } = useParams<{ stnId: string }>()
  const [station, setStation] = useState<Station | undefined>()
  const [obsLookup, setObsLookup] = useState<Record<string, ChartPt[]>>({})
  const [latestObsLookup, setLatestObsLookup] = useState<Record<string, ChartPt | undefined>>({})

  const load = useCallback(
    async (stnId: string) => {
      const { station: stn, obs } = await getStation(stnId)
      setStation(stn)
      setObsLookup(obs)
      setLatestObsLookup(mapValues(obs, v => last(v.filter(o => o.t < Date.now()))))
    },
    [stnId],
  )

  useTimer(
    () => {
      if (stnId === undefined) return
      load(stnId)
    },
    { interval: 60_000 },
  )

  useEffect(() => {
    if (stnId === undefined) return
    load(stnId)
    // test()
  }, [stnId])

  return {
    station,
    obsLookup,
    latestObsLookup,
  }
}
