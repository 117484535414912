import { useState } from 'react'
import { login, loginWithOTP } from 'src/api'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import dayjs, { Dayjs } from 'dayjs'

export const useViewModel = () => {
  const navigate = useNavigate()
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [phase, setPhase] = useState<'password' | 'otp'>('password')
  const [lastOtpRequestedAt, setLastOtpRequestedAt] = useState<Dayjs>(dayjs())

  const signInWithPassword = async () => {
    try {
      setLastOtpRequestedAt(dayjs())
      const isNot2FA = await login({ username, password })
      if (isNot2FA) {
        navigate('/')
        return
      }
      setLastOtpRequestedAt(dayjs())
      setPhase('otp')
    } catch (error) {
      message.error('Invalid username or password')
    }
  }

  const signInWithOTP = async (otp: string) => {
    try {
      await loginWithOTP({ username, otp })
      navigate('/')
    } catch (error) {
      message.error('Invalid username or password')
    }
  }

  return {
    username,
    setUsername,
    password,
    setPassword,
    signInWithPassword,
    signInWithOTP,
    phase,
    lastOtpRequestedAt,
  }
}
