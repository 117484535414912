export const Marker = ({ cx, cy, v }: { cx: number, cy: number, v: number }) => {
  const fontSize = 12
  const textWidth = (v?.toFixed(1).length ?? 0) * fontSize
  const size = textWidth + 8
  return <>
    <svg
      x={cx - size / 2}
      y={cy - size + 2}
      stroke={'#2265d4'}
      strokeWidth={(2 * 256) / size}
      width={size}
      height={size}
      viewBox="0 0 256 256"
    >
      <path
        fill={'#4285f4'}
        d="M128,246c0,0-88.5-92.4-88.5-143.5C39.5,51.4,73,10,128,10s88.5,41.4,88.5,92.5C216.5,153.6,128,246,128,246"
      />
    </svg>
    <svg>
      <text
        x={cx - textWidth / 4 - 1}
        y={cy - size / 2 + 2}
        fill="white"
        fontSize={fontSize}
        fontFamily="sans-serif"
      >
        {v?.toFixed(1) ?? ''}
      </text>
    </svg>
  </>
} 