import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import Styles from './Chart.module.scss'
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { ChartPt } from 'src/models'
import dayjs, { Dayjs } from 'dayjs'
import { ceil, debounce, floor, isNil, last, max, maxBy, min, minBy, range, round } from 'lodash'
import { Marker } from './Marker'
import { FormatedDate, timeToDDMMHHmm } from './utils'


export const Chart: React.FC<{
  className: string
  syncId: string
  data: ChartPt[]
  xLabel?: string
  yLabel?: string
  yAxisUnit: string
  lineColor?: string
  noMinor?: boolean
  grid?: boolean
  current?: Dayjs
  zoom?: { x: number; scale: number }
  onMove: (x?: number) => any
}> = ({
  className,
  syncId,
  data,
  xLabel,
  yLabel,
  yAxisUnit,
  lineColor,
  noMinor,
  grid,
  current,
  zoom,
  onMove,
}) => {
    const values = data.map(({ v }) => v)
    const times = data.map(({ t }) => t)
    const minY = minBy(values) ?? 0
    const maxY = maxBy(values) ?? 0
    const _minX = min(times) ?? 0
    const _maxX = max(times) ?? 0
    const minX = zoom ? zoom.x - (zoom.x - _minX) * zoom.scale : _minX
    const maxX = zoom ? zoom.x + (_maxX - zoom.x) * zoom.scale : _maxX
    const yAxis = [round(floor(minY) - 1, 0), round(ceil(maxY) + 1, 0)]
    const [yTickMajorInterval, yTicks] = useMemo(() => {
      const yRange = yAxis[1] - yAxis[0]
      const yOrder = Math.floor(Math.log10(yRange * 0.7))
      const yTickMinorInterval = Math.pow(10, yOrder - 1)
      const yTickMajorInterval = Math.pow(10, yOrder)
      const yTicks = []
      if (noMinor) {
        yTicks.push(
          ...range(
            floor(yAxis[0] / yTickMajorInterval) * yTickMajorInterval,
            yAxis[1],
            yTickMajorInterval,
          ).map(v => round(v, 1)),
        )
        yTicks.push(last(yTicks)! + yTickMajorInterval)
      } else {
        yTicks.push(
          ...range(
            floor(yAxis[0] / yTickMinorInterval) * yTickMinorInterval,
            yAxis[1],
            yTickMinorInterval,
          ).map(v => round(v, 1)),
        )
      }
      return [yTickMajorInterval, yTicks]
    }, [yAxis, noMinor])
    const xTicksFull = useMemo(() => {
      const indexes = ceil(dayjs(_maxX).diff(dayjs(_minX), 'hour'))
      const interval = Math.ceil(indexes / 12)
      const start = dayjs(_minX).startOf('hour').unix() * 1000
      return range(indexes).map(i => start + i * interval * 3600_000)
    }, [_minX, _maxX])
    const xTicks = xTicksFull.filter(v => v >= minX && v <= maxX)
    const currentTime = dayjs(current).unix() * 1000

    const move = useCallback(
      debounce((e: any) => onMove(e.activeLabel as any as number), 200, {
        leading: true,
      }),
      [],
    )

    return (
      <div className={classNames(Styles.chart, className, zoom && Styles.scrollable)}>
        {yAxisUnit && <label className={Styles.unit}>{yAxisUnit}</label>}
        <ResponsiveContainer width="100%" height={270}>
          <LineChart
            syncId={syncId}
            margin={{
              top: 40,
              bottom: xLabel ? 12 : -6,
              left: yLabel ? 6 : -24,
              right: 28,
            }}
            data={data}
            onMouseEnter={move}
            onMouseMove={move}
            onMouseLeave={() => onMove(undefined)}
          >
            {grid && <CartesianGrid className={Styles.grid} strokeDasharray="3 5" />}
            <YAxis
              className={Styles.YAxis}
              type="number"
              label={yLabel && <Label className={Styles.yLabel} value={yLabel} />}
              tickMargin={noMinor ? 1 : -7}
              fontSize="1rem"
              domain={yAxis}
              interval={0}
              ticks={yTicks}
              tickFormatter={v => (noMinor ? v : v % yTickMajorInterval === 0 ? `${v}➖` : '')}
            />
            <XAxis
              className={Styles.XAxis}
              type="number"
              allowDataOverflow={true}
              label={<Label className={Styles.xLabel} value={xLabel} />}
              fontSize="1rem"
              dataKey="t"
              tickFormatter={timeToDDMMHHmm}
              ticks={xTicks}
              domain={[minX, maxX]}
            />
            <Line
              dataKey={`v`}
              stroke={lineColor}
              strokeWidth={2}
              dot={({ cx, cy, payload: { t, v } }) => {
                if (t === currentTime && !isNil(v)) return <Marker cx={cx} cy={cy} v={v} />
                return <></>
              }}
            />
            <Line
              dataKey={`f`}
              stroke={lineColor}
              strokeWidth={2}
              opacity={0.5}
              strokeDasharray={'3 3'}
              dot={({ cx, cy, payload: { t, f } }) => {
                if (t === currentTime && !isNil(f)) return <Marker cx={cx} cy={cy} v={f} />
                return <></>
              }}
            />
            <Tooltip
              content={({
                active,
                payload,
                label,
              }: {
                active?: boolean
                payload?: any
                label?: number
              }) => {
                const data = payload?.[0]
                return (
                  active &&
                  data &&
                  !isNil(label) && (
                    <div className={Styles.chartTooltips}>
                      <div className={Styles.time}>{FormatedDate(label)}</div>
                      <div className={Styles.value}>{`${data.value}${yAxisUnit ?? ''}`}</div>
                    </div>
                  )
                )
              }}
            />
          </LineChart>
        </ResponsiveContainer>
        <div className={Styles.scrollHint}>
          <label>{'scroll to zoom'}</label>
        </div>
      </div>
    )
  }
