import dayjs from 'dayjs'

export const timeToDDMMHHmm = (time: number) => dayjs(time).format('D/M HH:mm')
export const FormatedDate = (time: any) => {
  const d = dayjs(new Date(time))
  return (
    <>
      {d.format('DD MMM')}
      <div />
      {d.format('HH:mm')}
    </>
  )
}
